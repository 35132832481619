<template>
  <b-card-code
      title="Új hozzáadása"
  >
    <!-- form -->
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row>
          <b-col md="3">
            <b-form-group
                label="Típus"
            >
              <validation-provider
                  #default="{ errors }"
                  name="típus"
                  rules="required"
                  vid="type"
              >
                <v-select
                    v-model="formData.type"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="title"
                    :options="typeOptions"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="3">
            <b-form-group
                label="Kérdés"
            >
              <validation-provider
                  #default="{ errors }"
                  name="kérdés"
                  rules="required"
                  vid="title"
              >
                <b-form-input
                    v-model="formData.title"
                    :state="errors.length > 0 ? false:null"
                    placeholder="kérdés"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="3">
            <b-form-group
                label="Kezdő dátum"
            >
              <validation-provider
                  #default="{ errors }"
                  name="kezdő dátum"
                  rules="required"
                  vid="start_date"
              >
                <flat-pickr
                    v-model="formData.start_date"
                    class="form-control"
                    :config="flatPickRConfig"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="3">
            <b-form-group
                label="Befejező dátum"
            >
              <validation-provider
                  #default="{ errors }"
                  name="befejező dátum"
                  rules="required"
                  vid="end_date"
              >
                <flat-pickr
                    v-model="formData.end_date"
                    class="form-control"
                    :config="flatPickRConfig"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group
                label="Címke"
            >
              <validation-provider
                  #default="{ errors }"
                  name="címke"
                  vid="tags"
              >
                <v-select
                    v-model="formData.tags"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="title"
                    multiple
                    taggable
                    push-tags
                    :options="tagOptions"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="12">
            <validation-provider
                #default="{ errors }"
                name="mező"
                vid="result_is_public"
            >
              <b-form-checkbox
                  v-model="formData.result_is_public"
                  :state="errors.length > 0 ? false:null"
              >
                Publikus az eredmény?
              </b-form-checkbox>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
        </b-row>

        <b-row>
          <b-col xs="12" class="mt-2 mb-3">
            <b-button
                variant="info"
                class="float-right"
                @click.prevent="addNewOption"
            >
              Új válasz
            </b-button>
            <span>Válaszok:</span><br/>
          </b-col>
        </b-row>
        <b-row
            v-for="option in formData.options"
            :key="option.id"
        >
          <!-- válaszok -->
          <b-col xs="11">
            <b-form-group
                label="Válasz"
            >
              <validation-provider
                  #default="{ errors }"
                  name="answer"
                  rules="required"
                  vid="value"
              >
                <b-form-input
                    v-model="option.title"
                    :state="errors.length > 0 ? false:null"
                    placeholder="válasz"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
              v-if="formData.options.length > 1"
              xs="12"
              md="1"
          >
            <b-button
                variant="danger"
                size="sm"
                class="float-right mt-2"
                @click.prevent="removeOption(option.id)"
            >
              <feather-icon
                  icon="Trash2Icon"
              />
            </b-button>
          </b-col>

        </b-row>

        <b-row>

          <!-- submit button -->
          <b-col class="mt-1">
            <b-button
                variant="primary"
                type="submit"
                class="float-right"
                @click.prevent="submitForm"
            >
              Mentés
            </b-button>
            <cancel-and-g-o-t-o-previous-page class="mr-2"/>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BButton, BFormCheckbox,
} from 'bootstrap-vue'
import {
  required,
} from '@validations'
import vSelect from 'vue-select'
import cancelAndGOTOPreviousPage from '@/views/pages/_components/_helper/cancelAndGOTOPreviousPage'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css';
import {Hungarian} from 'flatpickr/dist/l10n/hu.js'

export default {
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormCheckbox,
    vSelect,
    cancelAndGOTOPreviousPage,
    flatPickr,
  },
  data() {
    return {
      role: 'poll',
      formData: {
        type: '',
        title: '',
        start_date: '',
        end_date: '',
        result_is_public: false,
        tags: [],
        options: [
          { id: 1, title: '' }
        ]
      },
      flatPickRConfig: {
        enableTime: true,
        dateFormat: 'Y-m-d H:i',
        locale: Hungarian,
        allowInput: true
      },
      typeOptions: [],
      tagOptions: [],
      optionId: 2,
      optionSkeleton: { id: 0, title: '' },
      required,
    }
  },
  created() {
    this.$store.dispatch('fetchAllTagsForBootstrap').then(response => {
      this.tagOptions = response
    })

    this.initData()
  },
  methods: {
    initData(){
      this.$store.dispatch('pollTypes').then(response => {
        this.typeOptions = response.data.values
        this.formData.type = this.typeOptions[0]
      })
    },
    submitForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // clone formData
          let fd = JSON.parse(JSON.stringify(this.formData))
          fd.type = this.formData.type.value

          this.$store.dispatch('storePoll', fd).then(response => {
            this.$helpers.showSuccessToast()

            this.$acl.canListPermission(this.role)
                ? this.$router.push({name: 'modify-poll', params: {id: response.data.entity.id}})
                : this.$router.go(-1)
          }).catch(error => {
            if (error.response.status === 422) {
              this.$refs.simpleRules.setErrors(error.response.data.errors)
            } else {
              this.$helpers.showErrorToast(error.toString())
            }
          })
        }
      })
    },
    addNewOption(){
      let sk = this.$helpers.cloneObject(this.optionSkeleton)
      sk.id = this.optionId++
      this.formData.options.push(sk)
    },
    removeOption(id){
      let options = []

      if (this.formData.options.length) {
        this.formData.options.forEach(option => {
          if (option.id !== id) {
            options.push(this.$helpers.cloneObject(option))
          }
        })
      }

      this.formData.options = options
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
